const selectColors = ['#FF5722', '#FF9800', '#00BCD4', '#673AB7', '#00796B', '#FFC107'] // default colors for buttons select color
const customizeColorClass = '.hs-bar-customize-colors-list'
const customizeIconClass = '.hs-bar-customize-icons-list'
const signalRowClass = '.hs-bar-select-row'

class customizeBar {

    /**
     * Init common scripts
     */
    static initCustomize() {
        customizeBar.generateButtons();
        customizeBar.selectColor();
        customizeBar.changeIcon();
        customizeBar.selectSignal();
    }

    /**
     * Generate color buttons from array
     */
    static generateButtons() {
        selectColors.forEach(function (color) {
            $(customizeColorClass)
                .append('<span/>')
                .find('span').last().css('background', color).attr('data-color', color)
        });
    }

    /**
     * Generate color buttons from array
     */
    static generateSignals() {
        try {
            hs.config.signalsCopy.forEach(function (signal, index) {
                let signalsListRow = `
                <div class="swiper-slide">
                    <div class="hs-bar-select-row ${index === 0 ? 'active' : ''}" data-id="${index}">
                        <div class="hs-bar-select-type">${signal.titleTpe}</div>
                        <div class="hs-bar-select-name">${signal.signalTitle}</div>
                      </div>
                </div>
            `
                $('.hs-bar-select .swiper-wrapper').append(signalsListRow)
            });
        } catch (e) {
            console.warn('hs object not found')
        }


        $('.hs-bar-select-row').each(function () {
            let rowWidth = $(this).width() + 40
            $(this).css('width', rowWidth)
            $(this).parent().css('width', rowWidth)
        })
    }

    /**
     * Select color and replace hs.config prop
     * */
    static selectColor() {
        $(document).on('click', customizeColorClass + ' span', function () {
            let selectedColor = $(this).attr('data-color');
            if ($('.hs').hasClass('bounceBottom-enter-active') && $(this).parent().hasClass('loading') !== true) {
                hs.config.accentColor = selectedColor
                hs.modal.restart()
                $(customizeColorClass + ' span').removeClass('active');
                $(this).addClass('active')
                $(this).parent().addClass('loading')
            }

            constructorBar.inputHiddenProps()
        })
    }

    /**
     * Select icon and replace hs.config prop
     * */
    static changeIcon() {
        $(document).on('click', customizeIconClass + ' span', function () {
            let selectedIcon = $(this).html();
            $(customizeIconClass + ' span').removeClass('active');
            $(this).addClass('active')
            hs.config.signals[0].icon = selectedIcon
            hs.config.signals[0].iconType = $(this).attr('data-icon-name')
            hs.modal.restart()

            constructorBar.inputHiddenProps()
        })
    }

    /**
     * Select signal
     */
    static selectSignal() {
        $(document).on('click', signalRowClass, function () {
            let selectedSignal = $(this).data('id')
            hs.config.signals[0] = hs.config.signalsCopy[selectedSignal];
            constructorBar.inputHiddenProps()
            hs.modal.restart()
            $(signalRowClass).removeClass('active')
            $(this).addClass('active')
        })
    }
}

$(document).ready(() => {
    customizeBar.initCustomize();
})
